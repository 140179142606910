import React from 'react';
import { motion } from 'framer-motion';

function WhyRentWithUs() {
  return (
    <div
      className="relative bg-cover bg-center text-white py-20"
      style={{ backgroundImage: `url('about_us_01.jpg')` }} // Replace with your image path
    >
      {/* Overlay */}
      <div className="absolute inset-0 bg-black opacity-50"></div>

      {/* Content */}
      <div className="relative container mx-auto px-4 text-center">
        {/* Main Topic */}
        <motion.h2
          className="text-4xl font-bold mb-8 relative inline-block"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Why Renting A Scooter With Us
          <motion.span
            className="block h-1 bg-gradient-to-r from-red-500 to-yellow-500 mt-2 md:ml-[135px]"
            layoutId="underline"
            initial={{ width: 0 }}
            animate={{ width: '50%' }}
            transition={{ duration: 0.5 }}
          />
        </motion.h2>

        {/* Points Section */}
        <div className="space-y-8 text-lg">
          <motion.p
            className="font-semibold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            <strong>Count on POWERFUL SCOOTERS.</strong> You will get high quality for your trip in the Sri Lankan mountains.
          </motion.p>
          <motion.p
            className="font-semibold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.4 }}
          >
            <strong>NO KILOMETER LIMIT</strong> Drive as much as you want and visit also other cool towns like Nuwara Eliya.
          </motion.p>
          <motion.p
            className="font-semibold"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.6 }}
          >
            <strong>GREAT SERVICE</strong> We are available 24/7 if you have problems.
          </motion.p>
        </div>

        {/* Sub Topic: Scooter Rental Price */}
        <motion.h3
          className="text-2xl font-bold mt-16"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Scooter Rental Price
        </motion.h3>
        <motion.p
          className="mt-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          The scooter prices start from <b>10 USD / day</b>. Helmets are included (up to <b>2 per</b> scooter).
        </motion.p>
        <motion.p
          className="mt-2"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.4 }}
        >
          <strong>Optional Extra:</strong> Full insurance.
        </motion.p>

        {/* Sub Topic: Cancellation Policy */}
        <motion.h3
          className="text-2xl font-bold mt-8"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Cancellation Policy
        </motion.h3>
        <motion.p
          className="mt-14"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8, delay: 0.2 }}
        >
          You can cancel up to 48 hours in advance. Conditions apply.
        </motion.p>
      </div>
    </div>
  );
}

export default WhyRentWithUs;
