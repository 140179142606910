import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

const reviews = [
    {
        message: "It was an excellent experience to work with ABC web design agency...",
        name: "Kenneth Smith",
        designation: "CEO, ABC IT Company",
        image: "profile.png"
    },
    {
        message: "ABC web design agency has completely professional...",
        name: "Romy Carl",
        designation: "Senior Executive, XYZ Logistics",
        image: "profile.png"
    },
    {
        message: "Got website designed from them. Very creative and innovative...",
        name: "Goran Mackay",
        designation: "Company Name & Designation",
        image: "profile.png"
    },
    {
        message: "Another excellent review...",
        name: "John Doe",
        designation: "Manager, Some Company",
        image: "profile.png"
    },
    {
        message: "Yet another positive feedback...",
        name: "Jane Doe",
        designation: "CTO, Tech Company",
        image: "profile.png"
    },
    {
        message: "This agency is great...",
        name: "Sam Smith",
        designation: "Engineer, Build Inc.",
        image: "profile.png"
    },
    {
        message: "Highly recommend their services!",
        name: "Anna Johnson",
        designation: "Designer, Creative Co.",
        image: "profile.png"
    },
    {
        message: "Professional and efficient...",
        name: "Michael Brown",
        designation: "CEO, Innovate Ltd.",
        image: "profile.png"
    },
    {
        message: "Exceptional quality and support...",
        name: "Emily Davis",
        designation: "Product Manager, Techie Inc.",
        image: "profile.png"
    },
];

const ReviewSection = () => {
    const [index, setIndex] = useState(0);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const reviewsPerPage = isMobile ? 1 : 3;
    const totalSlides = Math.ceil(reviews.length / reviewsPerPage);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % totalSlides);
        }, 5000); // Change slides every 5 seconds

        return () => clearInterval(interval);
    }, [totalSlides]);

    const slideVariant = {
        enter: {
            x: '100%',
            opacity: 0,
        },
        center: {
            x: '0%',
            opacity: 1,
            transition: {
                duration: 0.5,
                ease: 'easeInOut',
            },
        },
        exit: {
            x: '-100%',
            opacity: 0,
            transition: {
                duration: 0.5,
                ease: 'easeInOut',
            },
        },
    };

    return (
        <div className="py-16 bg-white">
            <div className="text-center">
                <motion.h2
                        className="text-4xl font-extrabold relative inline-block"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                    >
                        Client Testimonials & Reviews
                        <motion.span
                            className="block h-1 bg-gradient-to-r from-red-500 to-yellow-500 mt-3 ml-[70px] md:ml-[110px]"
                            layoutId="underline"
                            initial={{ width: 0 }}
                            animate={{ width: '60%' }}
                            transition={{ duration: 0.5 }}
                        />
                    </motion.h2>
            </div>
            <div className="mt-8 overflow-hidden relative">
                <motion.div
                    className="flex"
                    initial="enter"
                    animate="center"
                    exit="exit"
                    variants={slideVariant}
                    key={index}
                >
                    {reviews.slice(index * reviewsPerPage, index * reviewsPerPage + reviewsPerPage).map((review, i) => (
                        <div key={i} className={`flex flex-col items-center justify-between ${isMobile ? 'w-full' : 'w-1/3'} px-4`}>
                            <div className="bg-yellow-500 p-4 rounded shadow-lg text-white text-center">
                                <p>{review.message}</p>
                            </div>
                            <div className="mt-4 flex flex-col items-center text-center">
                                <img className="rounded-full w-24 h-24 object-cover" src={review.image} alt={review.name} />
                                <h4 className="mt-2 font-semibold">{review.name}</h4>
                                <p className="text-gray-600">{review.designation}</p>
                            </div>
                        </div>
                    ))}
                </motion.div>
            </div>
        </div>
    );
};

export default ReviewSection;
