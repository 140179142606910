import React from 'react';
import { motion } from 'framer-motion';

const MapSection = () => {
    return (
        <motion.div
            className="relative bg-[#111827] p-4"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: 'easeInOut' }}
        >
            <div className="max-w-7xl mx-auto text-center py-4 mt-[30px]">
                <motion.h2
                    className="text-4xl font-extrabold relative inline-block text-white"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    OUR BIKE RENTAL LOCATIONS
                    <motion.span
                            className="block h-1 bg-gradient-to-r from-red-500 to-yellow-500 mt-2 md:ml-[135px] mb-[60px]"
                            layoutId="underline"
                            initial={{ width: 0 }}
                            animate={{ width: '50%' }}
                            transition={{ duration: 0.5 }}
                        />
                </motion.h2>
                <div className="mt-4 relative">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15843.157430169624!2d79.83927626429442!3d7.200047799999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae25aa0d2d25c11%3A0xd05be6c4ec61a3cb!2sSri%20Lanka%20Bike%20Rent!5e0!3m2!1sen!2slk!4v1693227635468!5m2!1sen!2slk"
                        width="100%"
                        height="450"
                        style={{ border: 0 }}
                        allowFullScreen=""
                        loading="lazy"
                        className="rounded-lg shadow-lg w-full"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Bike Rental Location Map"
                    ></iframe>
                    <button
                        onClick={() => window.open('https://maps.app.goo.gl/6EeDsqyRjhEYPskG6', '_blank')}
                        className="absolute bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition"
                    >
                        OPEN IN GOOGLE MAPS
                    </button>
                </div>
            </div>
        </motion.div>
    );
};

export default MapSection;
