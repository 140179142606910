import React from 'react';
import { motion } from 'framer-motion';

const Banner = ({ onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-center items-center" // Transparent overlay
    >
      {/* Container for image and close button */}
      <div className="relative bg-white rounded-lg p-4">
        {/* Image */}
        <img
          src="banner/Post.jpg" // Replace with your image URL
          alt="Banner"
          className="w-full max-w-lg mx-auto" // Image styling, max width
        />
        
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-2 right-2 bg-gray-200 text-black px-2 py-1 rounded-full hover:bg-gray-300"
        >
          X
        </button>
      </div>
    </motion.div>
  );
};

export default Banner;
