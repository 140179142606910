import React from 'react';
import { motion } from 'framer-motion';
import BookingForm from './BookingForm';

function AboutUs() {
    return (
        <div className="container mx-auto my-16 px-4 mt-[80px] md:mt-[80px]">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-[100px] items-center">
                {/* About Us Column (Left Side) - Hidden on smaller screens */}
                <div className="text-left space-y-4 order-1 hidden md:block">
                    <motion.h2
                        className="text-4xl font-extrabold relative inline-block"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                    >
                        Ella Scooter Rent Us
                        <motion.span
                            className="block h-1 bg-gradient-to-r from-red-500 to-yellow-500 mt-2"
                            layoutId="underline"
                            initial={{ width: 0 }}
                            animate={{ width: '100%' }}
                            transition={{ duration: 0.5 }}
                        />
                    </motion.h2>

                    <motion.p
                        className="text-lg text-gray-600 leading-relaxed"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, delay: 0.2 }}
                    >
                        Welcome to A ella Scooter Rent, conveniently located on Railway Road in the picturesque town of Ella, Sri Lanka. Just a mere 2 minutes away from the bustling Ella Railway Station, our rental service offers you the perfect opportunity to explore this stunning destination at your own pace.
                        Whether you're drawn to the lush greenery of the surrounding hills, the mesmerizing views from Ella Rock, or the tranquility of Ravana Falls, our fleet of scooters provides the ideal mode of transportation for your adventures.
                        <br/><br/>
                        Booking with us is easy! Simply reach out to our friendly team to secure your preferred scooter rental. Whether you're a solo traveler seeking solitude or a group of friends eager to embark on an unforgettable journey, A ella Scooter Rent is here to cater to your needs.
                        <br/><br/>
                        Experience the freedom of exploring Ella and its breathtaking surroundings on two wheels with A ella Scooter Rent. Contact us today to reserve your scooter and start planning your unforgettable Sri Lankan adventure!
                    </motion.p>
                </div>

                {/* Booking Form Column - Shown on all screen sizes */}
                <motion.div
                    className="relative order-2"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    transition={{ duration: 0.5, ease: 'easeInOut' }}
                    style={{
                        perspective: '1000px', // Perspective for 3D effect
                    }}
                >
                    <motion.div
                        className="overflow-hidden max-h-[500px] h-[500px] p-6 rounded-lg shadow-lg bg-gradient-to-b from-white to-gray-100 backdrop-blur-lg"
                        initial={{ opacity: 0, scale: 0.9, rotateX: 0, rotateY: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.8, ease: 'easeInOut', delay: 0.2 }}
                        style={{
                            transformStyle: 'preserve-3d',
                            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)', // Shadow for depth
                        }}
                        whileHover={{
                            rotateX: 5, // Slight tilt on hover
                            rotateY: -5,
                            boxShadow: '0 20px 50px rgba(0, 0, 0, 0.5)',
                        }}
                    >
                        {/* Scrollable container with scrollbar hidden */}
                        <motion.div
                            className="overflow-y-scroll h-full pr-2 scrollbar-hide"
                            style={{
                                WebkitOverflowScrolling: 'touch',
                                scrollBehavior: 'smooth',
                                transform: 'translateZ(20px)', // Creates depth for the inner form
                            }}
                        >
                            <BookingForm /> {/* No changes to BookingForm */}
                        </motion.div>
                    </motion.div>
                </motion.div>

                {/* About Us Column (Mobile Version) */}
                <div className="text-left space-y-4 order-1 block md:hidden">
                    <motion.h2
                        className="text-4xl font-extrabold relative inline-block"
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                    >
                        Ella Scooter Rent Us
                        <motion.span
                            className="block h-1 bg-gradient-to-r from-red-500 to-yellow-500 mt-2"
                            layoutId="underline"
                            initial={{ width: 0 }}
                            animate={{ width: '100%' }}
                            transition={{ duration: 0.5 }}
                        />
                    </motion.h2>
                </div>
            </div>
        </div>
    );
}

export default AboutUs;
