import React, { useState } from 'react';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import Footer from './components/Footer';
import BookingPopup from './components/BookingPopup';
import AboutUs from './components/WhyRentWithUs';
import DrivingPermitComponent from './components/DrivingPermitComponent';
import Gallery from './components/Gallery';
import ReviewSection from './components/Reviews';
import MapSection from './components/Location';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TrainTimetable from './components/TimeTable';
import ContactUs from './components/ContactUs';
import WhyRentWithUs from './components/WhyUs';
import ScrollToTopButton from './components/ScrollToTopButton';
import Banner from './components/Banner';
import ImageUpload from './components/Admin';
import PaymentResult from './components/PaymentConfirmation';

function HomePage() {
  const [bannerVisible, setBannerVisible] = useState(true); // State to manage the banner visibility

  const handleBannerClose = () => {
    setBannerVisible(false); // Hide the banner and show the rest of the content
  };

  return (
    <div className="min-h-screen bg-gray-100 relative">
      {/* Show the banner if it's still visible */}
      {bannerVisible && <Banner onClose={handleBannerClose} />}
      
      {/* Rest of the page content */}
      <Navbar />
      <HeroSection />
      <AboutUs />
      <DrivingPermitComponent />
      <WhyRentWithUs />
      <Gallery />
      <ReviewSection />
      <MapSection />
      <ScrollToTopButton />
      <BookingPopup /> 
      <Footer />
    </div>
  );
}

function TimeTable() {
  return (
    <div>
      <Navbar />
      <TrainTimetable />
      <BookingPopup /> 
      <Footer />
    </div>
  );
}


function Admin() {
  return (
    <div>
      <Navbar />
      <ImageUpload />
      <Footer />
    </div>
  );
}

function SAboutUs() {
  return (
    <div>
      <Navbar />
      <AboutUs />
      <DrivingPermitComponent />
      <WhyRentWithUs />
      <Gallery  />
      <ReviewSection />
      <MapSection />
      <BookingPopup />
      <Footer />
    </div>
  );
}

function SContacttUs() {
  return (
    <div>
      <Navbar />
      <ContactUs />
      <Footer />
    </div>
  );
}

function Payment() {
  return (
    <div>
      <Navbar />
      <PaymentResult />
      <Footer />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/timetable" element={<TimeTable />} />
        <Route path="/aboutus" element={<SAboutUs />} />
        <Route path="/contactus" element={<SContacttUs />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/payment" element={<Payment />} />
      </Routes>
    </Router>
  );
}

export default App;
