import React from 'react';
import { motion } from 'framer-motion';
import { FaFacebook, FaTwitter, FaInstagram, FaYoutube } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-[#111827] text-white py-10">
      <div className="container mx-auto px-4">
        <motion.div
          className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Column 1: Logo and Description */}
          <div className="text-center md:text-left">
            <motion.div
              className="mb-4"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
            >
              <img src="logo/Logo.png" alt="Company Logo" className="relative w-32 mx-auto md:mx-0 bottom-10" />
            </motion.div>
            <p className="relative text-gray-400 bottom-14">
              Scooter Rental offers the best scooter rental services. Enjoy our fleet of well-maintained scooters for city tours or everyday use!
            </p>
          </div>

          {/* Column 2: Contact Details */}
          <div className="text-center md:text-left">
            <h3 className="text-lg font-semibold mb-4">Contact Us</h3>
            <ul>
              <li className="mb-2">Station Road, Ella, Sri Lanka</li>
              <li className="mb-2">Email: ellascooter4@gmail.com</li>
              <li className="mb-2">Phone: +94 71 3213105</li>
              <li className="mb-2">WhatsApp: +94 71 3213105</li>
            </ul>
          </div>

          {/* Column 3: Navigation */}
          <div className="text-center md:text-left">
            <h3 className="text-lg font-semibold mb-4">Navigation</h3>
            <ul>
              <li className="mb-2"><a href="/" className="hover:text-gray-300">Home</a></li>
              <li className="mb-2"><a href="/traintimetable" className="hover:text-yellow-400">Train Timetable</a></li>
              <li className="mb-2"><a href="/aboutus" className="hover:text-yellow-400">About Us</a></li>
            </ul>
          </div>

          {/* Column 4: Privacy Policy and Terms */}
          <div className="text-center md:text-left">
            <h3 className="text-lg font-semibold mb-4">Legal</h3>
            <ul>
              <li className="mb-2"><a href="/privacy-policy" className="hover:text-gray-300">Privacy Policy</a></li>
              <li className="mb-2"><a href="/terms" className="hover:text-gray-300">Terms & Conditions</a></li>
            </ul>
          </div>
        </motion.div>

        {/* Social Media and Copyright */}
        <div className="mt-8 text-center">
          {/* Social Media Icons */}
          <div className="flex justify-center space-x-6 mb-4">
            <a href="https://web.facebook.com/profile.php?id=61556659178458&sk=about_overview" target="_blank" rel="noopener noreferrer">
              <FaFacebook className="text-2xl hover:text-yellow-500 transition duration-300" />
            </a>
            {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
              <FaTwitter className="text-2xl hover:text-yellow-500 transition duration-300" />
            </a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="text-2xl hover:text-yellow-500 transition duration-300" />
            </a> */}
            <a href="https://www.youtube.com/@powertechengineers3885/shorts" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="text-2xl hover:text-yellow-500 transition duration-300" />
            </a>
          </div>

          {/* Copyright Statement */}
          <p className="text-gray-500 text-sm">
            &copy; {new Date().getFullYear()} Yns Developers. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
