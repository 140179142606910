import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaArrowUp } from 'react-icons/fa';

const ScrollToTopButton = () => {
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const handleScroll = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrolledAmount = window.scrollY + windowHeight;

    // Debugging log
    console.log('Scrolled Amount:', scrolledAmount, 'Document Height:', documentHeight);

    // Check if scrolled to bottom
    if (scrolledAmount >= documentHeight - 50) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToHero = () => {
    const heroSection = document.getElementById('hero-section');
    heroSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <motion.button
      onClick={scrollToHero}
      className={`fixed bottom-16 md:bottom-5 right-5 bg-red-500 text-white p-4 rounded-full shadow-lg z-50 
      ${isScrolledToBottom ? 'block' : 'hidden'}`}
      initial={{ opacity: 0 }}
      animate={{ opacity: isScrolledToBottom ? 1 : 0 }}
      transition={{ duration: 0.5 }}
    >
      <FaArrowUp size={20} />
    </motion.button>
  );
};

export default ScrollToTopButton;
