import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Menu, X } from 'react-feather';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {/* Fixed wrapper for the moving text with background color #111827 */}
      {/* <div className="bg-[#111827] fixed top-0 left-0 w-full z-50"> */}
        {/* Moving text for medium (md) and larger screens */}
        {/* <motion.div
          className="hidden md:block bg-yellow-400 text-gray-900 text-center py-0 font-semibold"
          animate={{ x: ['-100%', '100%'] }} // Move from right to left
          transition={{
            repeat: Infinity,
            duration: 40, // Speed of movement
            ease: 'linear',
          }}
        >
          Welcome to our website! Check out the latest updates and offers!
        </motion.div> */}

        {/* Static text for small screens */}
        {/* <div className="block md:hidden bg-yellow-400 text-gray-900 text-center py-0 font-semibold text-[13px]">
          Welcome to Ella scooters! Check out the latest updates and offers!
        </div>
      </div> */}

      <nav className="bg-gray-900 text-white p-0.5 md:p-0 sticky md:top-0 top-6 z-40">
        <div className="flex justify-between items-center max-w-[368px] md:max-w-7xl mx-auto">
          {/* Left: Logo */}
          <div className="ml-2">
            <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
              <img src="logo/Logo.png" alt="Logo" className="relative md:h-[100px] md:w-[100px] h-[100px] w-[100px] top-2" />
            </motion.div>
          </div>

          {/* Right: Navigation Buttons */}
          <div className="hidden md:flex space-x-2 md:space-x-4 mr-2">
            {[
              { name: 'Home', path: '/' },
              { name: 'Train Timetable', path: '/timetable' },
              { name: 'About Us', path: '/aboutus' },
              { name: 'Contact Us', path: '/contactus' },
            ].map((navItem, index) => (
              <motion.a
                key={index}
                href={navItem.path}
                className="relative text-lg hover:text-yellow-400"
                whileHover={{ scale: 1.1 }}
              >
                {navItem.name}
                <motion.div
                  className="absolute inset-0 bg-gray-700 rounded-lg opacity-0 z-[-1]"
                  whileHover={{ scale: 1.2, opacity: 0.7 }}
                  transition={{ type: 'spring', stiffness: 300 }}
                />
              </motion.a>
            ))}
          </div>

          {/* Hamburger Menu for Mobile */}
          <div className="md:hidden">
            <button
              onClick={handleToggle}
              className="p-1 rounded-md text-gray-400 hover:text-yellow-400 focus:outline-none focus:ring-2 focus:ring-yellow-400 mt-[10px]"
            >
              {isOpen ? <X size={24} /> : <Menu size={24} />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            className="md:hidden bg-gray-800"
          >
            {[
              { name: 'Home', path: '/' },
              { name: 'Train Timetable', path: '/timetable' },
              { name: 'About Us', path: '/aboutus' },
              { name: 'Contact Us', path: '/contactus' },
            ].map((navItem, index) => (
              <motion.a
                key={index}
                href={navItem.path}
                className="block px-2 py-1 text-lg text-gray-400 hover:text-yellow-400 focus:text-red-500 active:text-red-500 hover:bg-gray-700"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleToggle}
              >
                {navItem.name}
              </motion.a>
            ))}
          </motion.div>
        )}
      </nav>

    </>
  );
};

export default Navbar;
