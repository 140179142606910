import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FaMotorcycle, FaCogs, FaFirstAid, FaRoad } from 'react-icons/fa';
import { GiFullMotorcycleHelmet } from "react-icons/gi";
import BookingForm from './BookingForm';

const vehicles = [
  {
    model: "Honda Dio 110cc",
    price: "$9.99 / day",
    image: "vehicles/DeluxeYellow.png",
    settings: [
      { icon: <GiFullMotorcycleHelmet />, text: "Helmets: 1 or 2" },
      { icon: <FaFirstAid />, text: "First-Aid Kit: Yes" },
      { icon: <FaCogs />, text: "Transmission: Auto" },
      { icon: <FaRoad />, text: "24/7 Roadside Assistance" },
      { icon: <FaMotorcycle />, text: "Mileage: Unlimited" },
    ],
  },
  {
    model: "TVS Ntoq 125cc",
    price: "$9.99 / day",
    image: "vehicles/TVS.png",
    settings: [
      { icon: <GiFullMotorcycleHelmet />, text: "Helmets: 1 or 2" },
      { icon: <FaFirstAid />, text: "First-Aid Kit: Yes" },
      { icon: <FaCogs />, text: "Transmission: Auto" },
      { icon: <FaRoad />, text: "24/7 Roadside Assistance" },
      { icon: <FaMotorcycle />, text: "Mileage: Unlimited" },
    ],
  },
  {
    model: "Safar Jeep 1956 cc",
    price: "$99.99 / day",
    image: "vehicles/jeep.png",
    settings: [
      { icon: <GiFullMotorcycleHelmet />, text: "With Driver: No" },,
      { icon: <FaFirstAid />, text: "First-Aid Kit: Yes" },
      { icon: <FaCogs />, text: "Transmission: Manual" },
      { icon: <FaRoad />, text: "24/7 Roadside Assistance" },
      { icon: <FaMotorcycle />, text: "Mileage: Unlimited" },
    ],
  },
  {
    model: "chevrolet spark",
    price: "$99.99 / day",
    image: "vehicles/Spark.png",
    settings: [
      { icon: <GiFullMotorcycleHelmet />, text: "With Driver: No" },,
      { icon: <FaFirstAid />, text: "First-Aid Kit: Yes" },
      { icon: <FaCogs />, text: "Transmission: Manual" },
      { icon: <FaRoad />, text: "24/7 Roadside Assistance" },
      { icon: <FaMotorcycle />, text: "Mileage: Unlimited" },
    ],
  },
  {
    model: "VIP 4WD Ford Ranger",
    price: "$449.99 / day",
    image: "vehicles/ford.png",
    settings: [
      { icon: <GiFullMotorcycleHelmet />, text: "With Driver: Yes" },,
      { icon: <FaFirstAid />, text: "First-Aid Kit: Yes" },
      { icon: <FaCogs />, text: "Transmission: Manual" },
      { icon: <FaRoad />, text: "24/7 Roadside Assistance" },
      { icon: <FaMotorcycle />, text: "Mileage: Unlimited" },
    ],
  },
  {
    model: "DFSK Glory",
    price: "$449.99 / day",
    image: "vehicles/Glory front.png",
    settings: [
      { icon: <GiFullMotorcycleHelmet />, text: "With Driver: Yes" },,
      { icon: <FaFirstAid />, text: "First-Aid Kit: Yes" },
      { icon: <FaCogs />, text: "Transmission: Manual" },
      { icon: <FaRoad />, text: "24/7 Roadside Assistance" },
      { icon: <FaMotorcycle />, text: "Mileage: Unlimited" },
    ],
  }
];

const HeroSection = () => {
  const [currentVehicle, setCurrentVehicle] = useState(0);
  const [isBookingOpen, setIsBookingOpen] = useState(false); // State to control popup visibility

  const handleNextSlide = () => {
    setCurrentVehicle((prev) => (prev + 1) % vehicles.length);
  };

  const handlePrevSlide = () => {
    setCurrentVehicle((prev) => (prev - 1 + vehicles.length) % vehicles.length);
  };

  const toggleBookingPopup = () => {
    setIsBookingOpen(!isBookingOpen);
  };

  useEffect(() => {
    const autoSlide = setInterval(handleNextSlide, 20000); // Slide every 10 seconds
    return () => clearInterval(autoSlide); // Cleanup interval on component unmount
  }, []);

  return (
    <section id="hero-section" className="bg-gray-900 text-white py-10">
      <div className="text-center mb-8">
        <h1 className="text-3xl sm:text-5xl font-bold">SELECT YOUR VEHICLE</h1>
        <p className="text-lg sm:text-xl mt-2 md:text-yellow-400">Reserve now and get the best offer</p>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center items-center">
          <button
            className="absolute left-2 sm:left-0 text-gray-400 hover:text-white p-2 sm:p-3 bg-gray-800 rounded-full sm:rounded-lg hover:bg-yellow-400 transition-colors duration-300 z-10"
            onClick={handlePrevSlide}
          >
            &lt;
          </button>

          <div className="grid grid-cols-1 sm:grid-cols-12 gap-4 md:ml-[100px] lg:ml-[150px]">
            <div className="col-span-2 hidden sm:flex flex-col justify-center items-start">
              <h2 className="text-4xl font-bold text-yellow-400 mb-4">
                {vehicles[currentVehicle].model}
              </h2>
              <p className="text-2xl mb-4">{vehicles[currentVehicle].price}</p>
              <button
                className="bg-red-500 text-white px-6 py-3 rounded hover:bg-red-600"
                onClick={toggleBookingPopup} // Toggle the booking form popup
              >
                Rent Now
              </button>
            </div>

            <div className="col-span-12 sm:col-span-6 flex flex-col sm:flex-row justify-center items-center">
              <div className="text-center sm:hidden w-full">
                <h2 className="text-3xl font-bold text-yellow-400 mb-2 w-full">
                  {vehicles[currentVehicle].model}
                </h2>
                <p className="text-xl mb-4 w-full">{vehicles[currentVehicle].price}</p>
              </div>
              <motion.img
                src={vehicles[currentVehicle].image}
                alt={vehicles[currentVehicle].model}
                className="w-full h-auto max-w-xs sm:max-w-lg"
                animate={{
                  rotateY: [0, 360],
                }}
                transition={{
                  repeat: Infinity,
                  duration: 30,
                  ease: "linear",
                }}
              />
            </div>

            <div className="col-span-12 sm:col-span-3 flex flex-col justify-center items-center sm:items-start sm:justify-start sm:text-left text-center mx-auto sm:mx-0 md:mt-[120px]">
              {vehicles[currentVehicle].settings.map((setting, index) => (
                <div key={index} className="flex items-center mb-4">
                  <div className="text-yellow-400 mr-2">{setting.icon}</div>
                  <span>{setting.text}</span>
                </div>
              ))}
              <button
                className="bg-red-500 text-white px-6 py-3 mt-4 rounded hover:bg-red-600 sm:hidden w-full"
                onClick={toggleBookingPopup} // Toggle the booking form popup
              >
                Rent Now
              </button>
            </div>
          </div>

          <button
  className="absolute right-2 sm:right-0 md:right-[20px] lg:right-[50px] text-gray-400 hover:text-white p-2 sm:p-3 bg-gray-800 rounded-full sm:rounded-lg hover:bg-yellow-400 transition-colors duration-300 z-10"
  onClick={handleNextSlide}
>
  &gt;
</button>
        </div>
      </div>

      {/* Popup form */}
      {isBookingOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="bg-white p-4 sm:p-6 rounded-lg shadow-lg max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg w-full relative"
            style={{ maxHeight: '80vh', overflowY: 'auto' }} // Adjusted height for responsiveness and added scrolling
          >
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={toggleBookingPopup}
            >
              X
            </button>
            <BookingForm />
          </motion.div>
        </div>
      )}
    </section>
  );
};

export default HeroSection;
