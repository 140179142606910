import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { MdCarRental } from "react-icons/md"; // Import the car rental icon
import { FaWhatsapp } from "react-icons/fa"; // Import WhatsApp icon
import BookingForm from './BookingForm'; // Import your BookingForm

function BookingPopup() {
  const [isBookingOpen, setIsBookingOpen] = useState(false);
  const [isWhatsAppOpen, setIsWhatsAppOpen] = useState(false);

  const toggleBookingPopup = () => {
    setIsBookingOpen(!isBookingOpen);
  };

  const toggleWhatsAppPopup = () => {
    setIsWhatsAppOpen(!isWhatsAppOpen);
  };

  return (
    <>
      {/* Sticky book button - responsive design */}
      <div className="fixed right-4 bottom-12 sm:right-6 sm:bottom-6 md:right-8 md:bottom-8 lg:right-10 lg:bottom-10 flex flex-col space-y-4">
        <button
          onClick={toggleWhatsAppPopup}
          className="w-16 h-16 sm:w-15 sm:h-15 md:w-20 md:h-20 lg:w-18 lg:h-18 bg-green-500 text-white rounded-full shadow-lg flex items-center justify-center"
        >
          <FaWhatsapp size="2em" /> {/* WhatsApp icon */}
        </button>
        <button
          onClick={toggleBookingPopup}
          className="w-16 h-16 sm:w-15 sm:h-15 md:w-20 md:h-20 lg:w-18 lg:h-18 bg-red-500 text-white rounded-full shadow-lg flex items-center justify-center"
        >
          <MdCarRental size="2em" /> {/* Car rental icon */}
        </button>
      </div>

      {/* WhatsApp popup */}
      {isWhatsAppOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="bg-white p-4 sm:p-6 rounded-lg shadow-lg max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg w-full relative"
            style={{ maxHeight: '80vh', overflowY: 'auto' }} // Adjusted height for responsiveness
          >
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={toggleWhatsAppPopup}
            >
              X
            </button>
            <a
              href="https://wa.me/+94713213105" // Replace with your WhatsApp number
              className="text-green-600 text-lg font-bold"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact us on WhatsApp
            </a>
          </motion.div>
        </div>
      )}

      {/* Booking popup */}
      {isBookingOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-40">
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            className="bg-white p-4 sm:p-6 rounded-lg shadow-lg max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg w-full relative"
            style={{ maxHeight: '80vh', overflowY: 'auto' }} // Adjusted height for responsiveness
          >
            <button
              className="absolute top-3 right-3 text-gray-500 hover:text-gray-700"
              onClick={toggleBookingPopup}
            >
              X
            </button>
            <BookingForm />
          </motion.div>
        </div>
      )}
    </>
  );
}

export default BookingPopup;
