import React, { useState } from "react";
import { motion } from "framer-motion";
import { Box, Dialog } from "@mui/material";

const TrainTimetable = () => {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (image) => {
    setSelectedImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <Box className="newspaper-container md:mt-[50px] md:mb-[50px]">
        <motion.div
          className="paper-background"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.5 }}
        >
          <h1 className="text-center text-3xl md:text-5xl font-bold vintage-title mb-6">
            Train Timetable
          </h1>

          <div className="flex flex-col justify-center items-center gap-4 md:gap-6">
            <motion.img
              src="timetable02.png"
              alt="Timetable 1"
              className="timetable-image"
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5 }}
              onClick={() => handleOpen("timetable02.png")}
            />
            <motion.img
              src="timetable01.png"
              alt="Timetable 2"
              className="timetable-image"
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5 }}
              onClick={() => handleOpen("timetable01.png")}
            />
          </div>
        </motion.div>

        {/* Popup Dialog for Image Zoom */}
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="md"
          fullWidth
          PaperProps={{
            style: { backgroundColor: "transparent", boxShadow: "none" },
          }}
        >
          <motion.img
            src={selectedImage}
            alt="Selected Timetable"
            className="popup-image"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
            onClick={handleClose}
          />
        </Dialog>
      </Box>
    </div>
  );
};

export default TrainTimetable;
