import React, { useState } from "react";
import { motion } from "framer-motion";
import axios from "axios"; // Ensure axios is installed: npm install axios

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [status, setStatus] = useState("");

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setStatus("Sending...");

        try {
            await axios.post(`${apiUrl}/api/v2/api/send-email`, formData); // Adjust the URL to match your backend route
            setStatus("Message sent successfully!");
        } catch (error) {
            setStatus("Failed to send message.");
        }
    };

    return (
        <div>
            <div className="text-center mb-8 mt-20">
                <motion.h2
                    className="text-4xl font-extrabold relative inline-block"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    RENT YOUR VEHICLE NOW
                </motion.h2>
                <motion.p
                    className="text-gray-600"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    CONTACT ELLA SCOOTER RENT US
                    <div className="flex justify-center">
                        <motion.span
                            className="block h-1 bg-gradient-to-r from-red-500 to-yellow-500 mt-2"
                            layoutId="underline"
                            initial={{ width: 0 }}
                            animate={{ width: '15%' }}
                            transition={{ duration: 0.5 }}
                        />
                    </div>
                </motion.p>
            </div>
            <div className="min-h-screen flex items-center justify-center bg-fixed bg-center">
                <div className="flex flex-col md:flex-row gap-12 p-4 w-full">

                    {/* Contact Details Container */}
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                        whileHover={{ scale: 1.05, boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)" }}
                        className="relative p-10 rounded-lg shadow-lg w-full md:w-[80%] text-center"
                    >
                        <div
                            className="absolute inset-0 bg-cover bg-center rounded-lg"
                            style={{
                                backgroundImage: `url('contact/contactus2.png')`,
                                opacity: 0.9,
                                zIndex: -1,
                            }}
                        ></div>

                        <div className="relative z-10 bg-white bg-opacity-50 backdrop-blur-md rounded-lg p-6 mt-[40px]">
                            <div className="mb-4">
                                <img src="logo\Logo.png" alt="Company Logo" className="mx-auto w-[200px] h-[200px] object-contain" />
                            </div>

                            <h2 className="text-3xl font-semibold text-gray-800 mb-4">Contact Us</h2>

                            <p className="text-gray-700 mb-2">Station Road, Ella, Sri Lanka</p>

                            <p className="text-gray-700 mb-2">Email: ellascooter4@gmail.com</p>

                            <p className="text-gray-700 mb-2">Phone: +94 71 3213105</p>

                            <p className="text-gray-700 mb-2">WhatsApp: +94 71 3213105</p>

                            <div className="mt-4">
                                <img src="contact\Wathsup.png" alt="WhatsApp QR Code" className="mx-auto w-64 h-64 object-contain" />
                            </div>
                        </div>
                    </motion.div>

                    {/* Contact Form Container */}
                    <motion.div
                        initial={{ opacity: 0, scale: 0.8 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                        whileHover={{ scale: 1.05, boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)" }}
                        className="relative p-10 rounded-lg shadow-lg w-full md:w-[80%] text-center"
                    >
                        <div
                            className="absolute inset-0 bg-cover bg-center rounded-lg"
                            style={{
                                backgroundImage: `url('contact/contactus2.png')`,
                                opacity: 0.9,
                                zIndex: -1,
                            }}
                        ></div>

                        <div className="relative z-10 bg-white bg-opacity-50 backdrop-blur-md rounded-lg p-6 mt-[110px]">
                            <h2 className="text-3xl font-semibold text-gray-800 mb-4">Get in Touch</h2>

                            <form className="space-y-4" onSubmit={handleSubmit}>
                                <div>
                                    <label htmlFor="name" className="block text-gray-700 text-lg mb-2">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleInputChange}
                                        className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="email" className="block text-gray-700 text-lg mb-2">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="message" className="block text-gray-700 text-lg mb-2">
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        name="message"
                                        value={formData.message}
                                        onChange={handleInputChange}
                                        rows="5"
                                        className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                    ></textarea>
                                </div>

                                <button
                                    type="submit"
                                    className="w-full py-2 px-4 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                                >
                                    Send Message
                                </button>
                            </form>
                            {status && <p className="mt-4 text-gray-700">{status}</p>}
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;
