import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentResult = () => {
  const location = useLocation();
  const [paymentStatus, setPaymentStatus] = useState(null);

  useEffect(() => {
    // Parse query parameters from the URL
    const queryParams = new URLSearchParams(location.search);

    // Assuming the Marx API sends 'status' and 'orderKey' parameters
    const status = queryParams.get('status');
    const orderKey = queryParams.get('orderKey');

    // Update the payment status based on the query parameters
    if (status === 'success') {
      setPaymentStatus('Payment successful!');
    } else if (status === 'failure') {
      setPaymentStatus('Payment failed. Please try again.');
    } else {
      setPaymentStatus('Unknown payment status.');
    }
  }, [location]);

  return (
    <div className="container mx-auto p-6 mt-[100px] mb-[100px]">
      <h1 className="text-3xl font-bold text-center mb-6">Payment Status</h1>
      {paymentStatus ? (
        <div className="text-center">
          <p className="text-2xl">{paymentStatus}</p>
          {/* Display order key or other details */}
          {location.search.includes('orderKey') && (
            <p className="text-lg mt-4">Order Key: {new URLSearchParams(location.search).get('orderKey')}</p>
          )}
        </div>
      ) : (
        <p className="text-center text-xl">Processing payment status...</p>
      )}
    </div>
  );
};

export default PaymentResult;
