import { useState } from 'react';
import { motion } from 'framer-motion';

const DrivingPermitComponent = () => {
    const [isPopupVisible, setPopupVisible] = useState(false);

    // Function to toggle popup visibility
    const togglePopup = () => {
        setPopupVisible(!isPopupVisible);
    };

    return (
        <div className="w-full min-h-screen bg-white flex flex-col items-center p-8 md:pt-16">
            {/* Title Section */}
            <div className="text-center mb-8">
                <motion.h2
                    className="text-4xl font-extrabold relative inline-block"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                >
                    APPLY YOUR DRIVING PERMIT
                </motion.h2>
                <motion.p
                    className="text-gray-600"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}>
                        GET YOUR DRIVING PERMIT IN SRI LANKA
                        <motion.span
                            className="block h-1 bg-gradient-to-r from-red-500 to-yellow-500 mt-2 md:ml-[135px]"
                            layoutId="underline"
                            initial={{ width: 0 }}
                            animate={{ width: '50%' }}
                            transition={{ duration: 0.5 }}
                        />
                </motion.p>

            </div>

            {/* Image Section with Framer Motion effects */}
            <motion.div
                className="w-full max-w-4xl mb-8 relative"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                whileHover={{ scale: 1.05, rotate: [0, 5, -5, 0], transition: { duration: 0.8 } }}
                transition={{ duration: 1 }}
            >
                {/* Shadow Effect */}
                <motion.div
                    className="absolute inset-0 w-full h-full bg-gradient-to-r from-gray-400 to-transparent rounded-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 0.5 }}
                    transition={{ delay: 0.5, duration: 1 }}
                />

                <motion.img
                    src="contact/driving-permit.jpg" // Replace with the actual image path
                    alt="Driving Permit with Bikes"
                    className="w-full h-auto object-cover rounded-md shadow-lg"
                    whileHover={{ scale: 1.05, rotate: 2, transition: { duration: 0.3 } }}
                />
            </motion.div>

            {/* Description Section */}
            <motion.div
                className="text-center mb-8 max-w-4xl"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5, duration: 1 }}
            >
                <p className="text-gray-700 mb-4">
                    Sri Lanka is one of the very few countries which requires you to get your license
                    verified in the country before you enjoy an ecstatic drive here. In other words, it is
                    illegal to drive on an IDP or foreign national license here. It is mandatory to get
                    your non-Sri Lankan license ‘verified’ here.
                </p>
                <p className="text-gray-700">
                    To save your precious time, we are determined to help you arrange your temporary driving
                    license beforehand.
                </p>
                <p className="text-gray-700 font-bold mt-4">
                    For more information, feel free to contact us.
                </p>
            </motion.div>

            {/* Button Section */}
            <motion.div
                className="mb-8"
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 1, duration: 1 }}
            >
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="px-6 py-3 bg-red-500 text-white text-lg rounded-lg hover:bg-red-600"
                    onClick={togglePopup} // Toggle the popup on button click
                >
                    Call Us Now!
                </motion.button>
            </motion.div>

            {/* Popup Section */}
            {isPopupVisible && (
                <motion.div
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <motion.div
                        className="bg-white rounded-lg p-6 w-full max-w-lg mx-4 md:mx-auto md:max-w-md md:p-8"
                        initial={{ scale: 0.8 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0.8 }}
                        transition={{ duration: 0.3 }}
                    >
                        {/* Popup Header with Close Button */}
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-xl md:text-2xl font-bold md:ml-[130px] ml-[100px]">Contact Us</h2>
                            <button onClick={togglePopup} className="text-gray-600 hover:text-gray-900 text-lg md:text-xl">
                                X
                            </button>
                        </div>

                        {/* Contact Details */}
                        <div className="text-center">
                            <img src="logo/Logo.png" alt="Scooter Rental Logo" className="mx-auto mb-4 w-24 md:w-32" />
                            <p className="text-gray-700 mb-2 text-sm md:text-base">Station Road, Ella, Sri Lanka</p>
                            <p className="text-gray-700 mb-2 text-sm md:text-base">Email: ellascooter4@gmail.com</p>
                            <p className="text-gray-700 mb-2 text-sm md:text-base">Phone: +94 71 3213105</p>
                            <p className="text-gray-700 text-sm md:text-base">WhatsApp: +94 71 3213105</p>
                            <img src="contact/Wathsup.png" alt="Scooter Rental Logo" className="mx-auto mb-4 w-24 md:w-64 md:h-64 mt-[12px]" />
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </div>
    );
};

export default DrivingPermitComponent;
